// URL HTTP Request untuk data-data vendor
export let URLVendor = "https://ct-backend-dev.pointer.co.id/vendor/";

// URL HTTP Request untuk data-data admin
export let URLAdmin = "https://ct-backend-dev.pointer.co.id";

// URL Socket
export let URLSocket = "http://localhost:8000";





